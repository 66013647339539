import React, {useContext, useEffect} from 'react';
import {Container} from "react-bootstrap";
import {observer} from "mobx-react-lite";
import BookList from "../components/BookList";
import {fetchBook} from "../http/bookAPI";
import {Context} from "../index";
import Pages from "../components/Pages";
import Search from "../components/Search";

const BookShelf = observer(() => {
  const {book} = useContext(Context)

  useEffect(() => {
    fetchBook(book.page, book.limit, book.search).then(data => {
      book.setBooks(data.rows)
      book.setTotalCount(data.count)
    })
  }, [])

  useEffect(() => {
    fetchBook(book.page, book.limit, book.search).then(data => {
      book.setBooks(data.rows)
      book.setTotalCount(data.count)
    })
  }, [book.page, book.search])

  return (
    <Container className={'mt-3'}>
      <Search/>
      <BookList className={'mx-auto'}/>
      <Pages className={'mx-auto'}/>
    </Container>
  );
});

export default BookShelf;