import {$authHost, $host} from "./index";


export const createBook = async (book, setLoadedStatus, setProgressValue) => {
  $authHost.onUploadProgress = progressEvent =>
  {setLoadedStatus(true); setProgressValue(progressEvent.loaded); console.log(progressEvent.loaded)}

  const {data} = await $authHost.post('api/book', book)
  return data
}

export const deleteBook = async (id) => {
  const {data} = await $host.delete('api/book/' + id)
  return data
}

export const fetchBook = async (page, limit= 5, search='') => {
  const {data} = await $host.get('api/book', {params: {
      page, limit, search
    }})
  return data
}

export const fetchOneBook = async (id) => {
  const {data} = await $host.get('api/book/' + id)
  return data
}