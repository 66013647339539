import React, { useState } from 'react';
import {Button, Col, Container, FloatingLabel, Form, InputGroup, ProgressBar, Row} from "react-bootstrap";
import Image from "react-bootstrap/Image";
import BookViewPDF from "../components/BookViewPDF";
import {createBook} from "../http/bookAPI";
import {pdfjs} from "react-pdf";
import { ImPaste } from "react-icons/im";


const BookCreate = () => {
  const [book, setBook] = useState({
    title: '',
    author: '',
    year_release: 2024,
  });
  const [blobCover, setBlobCover] = useState(null);
  const [blobPDF, setBlobPDF] = useState(null);
  const [loadedStatus, setLoadedStatus] = useState(false);
  const [progressValue, setProgressValue] = useState(0);

  const convertPDFtoImage = async (blob) => {
    const arrayBuffer = await blob.arrayBuffer();
    const pdfData = new Uint8Array(arrayBuffer);

    console.log(pdfjs.version)
    pdfjs.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

    const loadingTask = pdfjs.getDocument({ data: pdfData });
    const pdf = await loadingTask.promise;
    const firstPage = await pdf.getPage(1);

    const canvas = document.createElement('canvas');
    const viewport = firstPage.getViewport({ scale: 2 }); // You can adjust the scale as needed

    canvas.height = viewport.height;
    canvas.width = viewport.width;

    const renderContext = {
      canvasContext: canvas.getContext('2d'),
      viewport: viewport,
    };

    await firstPage.render(renderContext).promise;

    // Convert canvas to blob
    return new Promise((resolve) => {
      canvas.toBlob((blob) => {
        resolve(blob);
      }, 'image/png'); // You can change the format as needed
    });
  };

  const onChangeCover = (file) => {
    if (!file) {
      setBlobCover(null);
      return;
    }
    setBlobCover(new Blob([file], { type: file.type }));
  };

  const onChangePDF = (file) => {
    if (!file) {
      setBlobPDF(null);
      return;
    }
    const blob = new Blob([file], { type: file.type })
    console.log(URL.createObjectURL(blob))
    setBlobPDF(blob);
  };

  if (!blobCover && !!blobPDF) {
    convertPDFtoImage(blobPDF).then(blob => setBlobCover(blob));
  }

  const onAddBook = () => {
    setLoadedStatus(true);
    const formData = new FormData()
    formData.append('title', book.title)
    formData.append('author', book.author)
    formData.append('year_release', book.year_release)

    if (blobCover) {
      formData.append('cover', blobCover);
    }
    if (blobPDF) {
      formData.append('file', blobPDF);
    }

    createBook(formData, setLoadedStatus, setProgressValue).finally(
        () => {
          setBlobCover(null);
          setBlobPDF(null);
          setBook({
            title: '',
            author: '',
          });
          setLoadedStatus(false);
          setProgressValue(0);
        }
    );
  };

  const onLoadCover = () => {
    if (!!blobPDF){
      convertPDFtoImage(blobPDF).then(blob => setBlobCover(blob));
    }
  }

  // Обработчик изменения полей формы
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setBook({
      ...book,
      [name]: value,
    });
  };

  return (
    <Container className={'mt-3'}>
      <Row>
        {
          loadedStatus ? <Col><h1>Книга загружается</h1></Col> : <Col><h1>Добавить книгу</h1></Col>
        }
      </Row>
      {
        loadedStatus ? <Row>
          <ProgressBar now={progressValue} label={`${progressValue}%`}/>;
        </Row> : null
      }
      <Row>
        <Col className={'col-12 col-md-6 mb-3'}>
          {
            blobCover ?
              <Image width={"100%"} src={URL.createObjectURL(blobCover)} />
              :
              <Image width={"100%"} src={'https://dummyimage.com/600x771/c0c0c0/aaa'} />}
        </Col>
        <Col className={'mb-3'}>
          <Form>
            <Row>
              <Form.Group as={Col} className="position-relative col-12 col-md-6">
                <FloatingLabel label="Название" className="mb-2">
                  <Form.Control
                    type={'text'}
                    name="title" // Указать имя поля
                    placeholder="Название"
                    required
                    isInvalid={!book.title} // Проверить значение и установить состояние недопустимости
                    value={book.title}
                    onChange={handleInputChange} // Обработчик изменения поля
                  />
                  <Form.Control.Feedback type="invalid">
                    Пожалуйста введите название.
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} className="position-relative col-12 col-md-6">
                <FloatingLabel label="Автор" className="mb-3">
                  <Form.Control
                    type={'text'}
                    name="author" // Указать имя поля
                    placeholder="Автор"
                    required
                    isInvalid={!book.author} // Проверить значение и установить состояние недопустимости
                    value={book.author}
                    onChange={handleInputChange} // Обработчик изменения поля
                  />
                  <Form.Control.Feedback type="invalid">
                    Пожалуйста введите автора.
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} className="position-relative col-12 col-md-6">
                <FloatingLabel label="Год" className="mb-3">
                  <Form.Control
                      type="number" min="1000" max="3000"
                      name="year_release" // Указать имя поля
                      placeholder="Год выпуска"
                      required
                      isInvalid={!book.year_release} // Проверить значение и установить состояние недопустимости
                      value={book.year_release}
                      onChange={handleInputChange} // Обработчик изменения поля
                  />
                  <Form.Control.Feedback type="invalid">
                    Пожалуйста укажите год выпуска.
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Form.Group>

            </Row>
            <Row>
              <Form.Group as={Col} className="position-relative col-12">
                <InputGroup className="mb-2">
                  <Form.Control
                    type={'file'}
                    onChange={(event) => onChangePDF(event.target.files[0] || null)}
                    accept=".pdf"
                  />
                  <InputGroup.Text>Книга</InputGroup.Text>
                </InputGroup>
              </Form.Group>
              <Form.Group as={Col} className="position-relative col-12">
                <InputGroup className="mb-3">
                  <Form.Control
                    type={'file'}
                    onChange={(event) => onChangeCover(event.target.files[0] || null)}
                    accept=".jpg,.jpeg,.png"
                  />
                  <Button onClick={()=>{
                    navigator.clipboard.read().then(async (clipboardItems) => {
                      for (const clipboardItem of clipboardItems) {
                        for (const type of clipboardItem.types) {
                          if (type === 'image/png') {
                            const blob = await clipboardItem.getType(type);
                            // we can now use blob here
                            setBlobCover(blob)
                            onChangeCover(blob)
                          }
                        }
                      }
                    })
                  }}>
                    <ImPaste />
                  </Button>
                  <InputGroup.Text>Обложка</InputGroup.Text>
                </InputGroup>
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Col} className="position-relative col-12 col-md-4">
                <Button className={'w-100'} variant={'outline-dark'} onClick={onLoadCover}>Обложка из файла</Button>
              </Form.Group>
              <Form.Group as={Col} className="position-relative col-12 col-md-4">
                <Button className={'w-100'} variant={'outline-success'} onClick={onAddBook}>Добавить</Button>
              </Form.Group>
            </Row>
          </Form>
        </Col>
        <Col className={'col-12'}>
          {blobPDF ? <BookViewPDF blob={blobPDF} /> : <></>}
        </Col>
      </Row>
    </Container>
  );
};

export default BookCreate;
