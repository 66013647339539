import React, {useContext} from 'react';
import {Row} from "react-bootstrap";
import {observer} from "mobx-react-lite";
import {Context} from "../index";
import BookItem from "./BookItem";

const BookList =  observer(() => {
  const {book} = useContext(Context)
  return (
    <Row>
      {book.books.map(book => {
         return <BookItem key={book.id} book={book}/>
        }
      )}
    </Row>
  );
});

export default BookList;