import React, {useContext} from 'react';
import {Row, Table} from "react-bootstrap";
import {observer} from "mobx-react-lite";
import {Context} from "../index";
import BookDeleteRow from "./BookDeleteRow";

const BookDeleteTable =  observer(() => {
  const {book} = useContext(Context)
  return (
    <Table striped bordered hover variant="dark" className={'mt-3'}>
      <thead>
      <tr>
        <th>#</th>
        <th width={100}>Обложка</th>
        <th>Название</th>
        <th>Автор</th>
      </tr>
      </thead>
      <tbody>
      {book.books.map(book => {
          return <BookDeleteRow key={book.id} book={book}/>
        }
      )}
      </tbody>
    </Table>
  );
});

export default BookDeleteTable;