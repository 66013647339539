import React from 'react';
import {Button, Card, Col, Container, Row} from "react-bootstrap";
import {useHistory} from "react-router-dom";
import {BOOK_CREATE_ROUTER, BOOK_DELETE_ROUTER} from "../utils/consts";

const Admin = () => {
  const history = useHistory()

  return (
    <Container className={'mt-3 ml-auto d-flex flex-column'}>
      <Row>
        <Col className={'col-12 col-md-6'}>
          <Card>
            <Card.Header>Книги</Card.Header>
            <Card.Body>
              <Button className={'mb-3 w-100'}
                      variant={"outline-dark"}
                      onClick={() => history.push(BOOK_CREATE_ROUTER)}
              >
                Добавить книгу
              </Button>
              <Button className={'mb-3 w-100'}
                      variant={"outline-dark"}
                      onClick={() => history.push(BOOK_DELETE_ROUTER)}
              >
                Удалить книги
              </Button>
            </Card.Body>
          </Card>
        </Col>
        <Col className={'col-12 col-md-6'}>
          <Card>
            <Card.Header>Группы</Card.Header>
            <Card.Body>
              <Button className={'mb-3 w-100'}
                      variant={"outline-dark"}
              >
                Добавить группу
              </Button>
              <Button className={'mb-3 w-100'}
                      variant={"outline-dark"}
              >
                Удалить группу
              </Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Admin;