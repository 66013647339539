import React from 'react';
import { Viewer, SpecialZoomLevel } from '@react-pdf-viewer/core';
import { Worker } from '@react-pdf-viewer/core';
import { pageNavigationPlugin } from '@react-pdf-viewer/page-navigation';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/page-navigation/lib/styles/index.css';

const renderPage = (props) => {
  return (
    <>
      {props.canvasLayer.children}
      <div style={{ userSelect: 'none'}}>
        {props.textLayer.children}
      </div>
      {props.annotationLayer.children}
    </>
  );
};

const url_pdf_worker = process.env.THIS_URL + 'module/pdf.worker.min.js';

const BookViewPDF = React.memo(({ blob }) => {
  const pageNavigationPluginInstance = pageNavigationPlugin();
  const { CurrentPageInput, GoToFirstPageButton, GoToLastPageButton, GoToNextPageButton, GoToPreviousPageButton } = pageNavigationPluginInstance;

  return (
    <div style={{margin: '0 0 1em 0',}}>
      <div style={{
        alignItems: 'center',
        backgroundColor: '#eeeeee',
        borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
        display: 'flex',
        justifyContent: 'center',
        padding: '4px',
      }}>
        <div style={{ padding: '0px 2px' }}>
          <GoToFirstPageButton />
        </div>
        <div style={{ padding: '0px 2px' }}>
          <GoToPreviousPageButton />
        </div>
        <div style={{ padding: '0px 2px' }}>
          <CurrentPageInput />
        </div>
        <div style={{ padding: '0px 2px' }}>
          <GoToNextPageButton />
        </div>
        <div style={{ padding: '0px 2px' }}>
          <GoToLastPageButton />
        </div>
      </div>
        <div style={{
          border: '1px solid rgba(0, 0, 0, 0.3)',
          height: '100vh',
          // padding: '1em',
        }}>
          <Worker workerUrl={'https://el.bibl.kasict.ru/module/pdf.worker.min.js'}>
            <Viewer
              fileUrl={URL.createObjectURL(blob)}
              defaultScale={SpecialZoomLevel.PageWidth}
              plugins={[pageNavigationPluginInstance]}
              renderPage={renderPage}
            />
          </Worker>
        </div>
    </div>
  );
});

export default BookViewPDF;
