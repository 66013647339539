import {
  ADMIN_ROUTE,
  BOOK_CREATE_ROUTER,
  BOOK_DELETE_ROUTER,
  BOOK_ROUTE,
  LOGIN_ROUTE,
  REGISTRATION_ROUTE,
  SHELF_ROUTE
} from "./utils/consts";
import Admin from "./pages/Admin";
import Auth from "./pages/Auth";
import BookShelf from "./pages/BookShelf";
import BookPage from "./pages/BookPage";
import BookCreate from "./pages/BookCreate";
import BookDelete from "./pages/BookDelete";

export const authRoutes = [
  {
    path: ADMIN_ROUTE,
    Component: Admin
  },
  {
    path: BOOK_DELETE_ROUTER,
    Component: BookDelete
  },
  {
    path: BOOK_CREATE_ROUTER,
    Component: BookCreate
  },
]

export const publicRoutes = [
  {
    path: LOGIN_ROUTE,
    Component: Auth
  },
/*  {
    path: REGISTRATION_ROUTE,
    Component: Auth
  },*/
  {
    path: SHELF_ROUTE,
    Component: BookShelf
  },
  {
    path: BOOK_ROUTE + '/:id',
    Component: BookPage
  },
]