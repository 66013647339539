import React, {useContext} from 'react';
import {Switch, Route, Redirect} from 'react-router-dom'
import {authRoutes, publicRoutes} from "../routes";
import {SHELF_ROUTE} from "../utils/consts";
import {Context} from "../index";

const AppRouter = () => {
  const {user} = useContext(Context);
  console.log(user.isAuth)
  return (
    <Switch>
      {user.isAuth && authRoutes.map(({path, Component}) =>
        <Route key={path} path={path} component={Component} exact/>
      )}
      {publicRoutes.map(({path, Component}) =>
        <Route key={path} path={path} component={Component} exact/>
      )}
      <Redirect to={SHELF_ROUTE}/>
    </Switch>
  );
};

export default AppRouter;