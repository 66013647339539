import React, {useContext, useEffect} from 'react';
import {Button, Modal} from "react-bootstrap";
import {deleteBook, fetchBook} from "../http/bookAPI";
import {Context} from "../index";

const ModalDeleteBook = ({book, show, onHide}) => {
  const {book: bookContext} = useContext(Context)
  const onDeleteBook = () => {
    deleteBook(book.id).then();
    const updatedBooks = bookContext.books.filter((bookItem) => bookItem.id !== book.id);
    bookContext.setBooks(updatedBooks);
    onHide();
  }

  return (
    <Modal
      show={show}
      onHide={onHide}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Удалить книгу {book.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Вы действительно хотите удалить эту книгу?
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Закрыть
        </Button>
        <Button variant="danger" onClick={onDeleteBook}>
          Удалить
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalDeleteBook;