import React, {useContext, useEffect, useState} from 'react';
import {observer} from "mobx-react-lite";
import {Context} from "../index";
import {Form} from "react-bootstrap";

const Search = observer(() => {
  const {book} = useContext(Context)

  useEffect(()=>{
    book.setSearch('')
  }, [])

  const onChangeSearch = (e) => {
    book.setSearch(e.target.value)
    book.setPage(1)

  }

  return (
    <Form.Control
      type="text"
      placeholder="Поиск"
      className=" mr-sm-2"
      value = {book.search}
      onChange = {onChangeSearch}
    />
  );
});

export default Search;
