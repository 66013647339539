import React, {useContext} from 'react';
import {NavLink, useHistory} from "react-router-dom";
import {observer} from "mobx-react-lite";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import {Button, Container} from "react-bootstrap";
import {Context} from "../index";
import {ADMIN_ROUTE, LOGIN_ROUTE, SHELF_ROUTE} from "../utils/consts";

const NavBar = observer(() => {
  const {user} = useContext(Context)
  const history = useHistory()

  const logOut = () => {
    user.logout()
  }

  return (
    <Navbar bg="dark" variant="dark">
      <Container>
        <NavLink style={{color: 'white', textDecoration: 'none', fontSize: '2em'}} to={SHELF_ROUTE}>Виртуальный читальный зал ГАПОУ ККАСиЦТ</NavLink>
        {user.isAuth ?
          <Nav className="ml-auto" style={{color: 'white'}}>
            <Button
              variant={"outline-light"}
              onClick={() => history.push(ADMIN_ROUTE)}
            >
              Админ панель
            </Button>
            <Button
              variant={"outline-light"}
              onClick={() => logOut()}
              className="ml-2"
            >
              Выйти
            </Button>
          </Nav>
          :
          <Nav className="ml-auto" style={{color: 'white'}}>
            <Button variant={"outline-light"} onClick={() => history.push(LOGIN_ROUTE)}>Авторизация</Button>
          </Nav>
        }
      </Container>
    </Navbar>

  );
});

export default NavBar;