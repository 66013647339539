import React, {useEffect, useState} from 'react';
import {Col, Container, Row, Table} from "react-bootstrap";
import {useParams} from "react-router-dom";
import {fetchOneBook} from "../http/bookAPI";
import Image from "react-bootstrap/Image";
import BookViewPDF from "../components/BookViewPDF";

const BookPage = () => {
  const {id} = useParams()
  const [book, setBook] = useState({})
  const [blobPDF, setBlobPDF] = useState(null);
  const [statusLoaded, setStatusLoaded] = useState(false)

  useEffect(()=>{
    fetchOneBook(id).then(data => {
      setBook(data.book)
    })
  }, []);

  useEffect(() => {
    setStatusLoaded(false);
    const fetchData = async () => {
      if (book && book.file) {
        try {
          const response = await fetch(process.env.REACT_APP_API_URL + book.file);
          if (response.ok) {
            const blob = await response.blob();
            setBlobPDF(blob);
          } else {
            // Обработка ошибки при неуспешном запросе
            console.error('Ошибка при запросе:', response.status, response.statusText);
          }
        } catch (error) {
          // Обработка других ошибок
          console.error(error);
        }
      } else {
        setBlobPDF(null);
      }
    };

    fetchData().finally(() => setStatusLoaded(true));
  }, [book]);

  return (
    <Container className={'mt-3 d-flex flex-column'}>
      {statusLoaded ? null : <Row><Col style={{ fontSize: '3em' }}>Книга загружается</Col></Row>}
      <Row>
        <Col className={'col-12 col-md mb-3'}>
          <Image width={"100%"} src={book.cover ? process.env.REACT_APP_API_URL + book.cover : 'https://dummyimage.com/600x771/c0c0c0/aaa'}/>
        </Col>
        <Col>
          <Table>
            <tbody>
            <tr>
              <td>Название</td>
              <td>{book.title}</td>
            </tr>
            <tr>
              <td>Автор</td>
              <td>{book.author}</td>
            </tr>
            <tr>
              <td>Год выпуска</td>
              <td>{book.year_release}</td>
            </tr>
            </tbody>
          </Table>
        </Col>
        <Col className={'col-12'}>
          {blobPDF ? <BookViewPDF blob={blobPDF} /> : <></>}
        </Col>
      </Row>
    </Container>
  );
};

export default BookPage;