import {makeAutoObservable} from "mobx";

export default class BookStore {
  constructor() {
    this._books = []
    this._page = 1
    this._limit = 8
    this._totalCount = 0
    this._search = ''
    makeAutoObservable(this)
  }

  setBooks(books) {
    this._books = books;
  }

  get books(){
    return this._books
  }

  setPage(page) {
    this._page = page
  }

  get page() {
    return this._page
  }

  get limit() {
    return this._limit
  }

  setTotalCount(count) {
    this._totalCount = count
  }

  get totalCount() {
    return this._totalCount
  }

  setSearch(search) {
    this._search = search
  }

  get search() {
    return this._search
  }
}