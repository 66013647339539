import {BrowserRouter} from "react-router-dom";
import AppRouter from "./components/AppRouter";
import React, {useContext, useEffect, useState} from "react";
import NavBar from "./components/NavBar";
import {check} from "./http/userAPI";
import {Context} from "./index";
import {Spinner} from "react-bootstrap";

function App() {
  const {user} = useContext(Context)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    check().then(data => {
      if (data){
        user.setUser(data)
        user.setIsAuth(true)
      } else {
        user.setUser({})
        user.setIsAuth(false)
      }

    }).finally(() => setLoading(false)).catch(
        () => {
          user.setUser({})
          user.setIsAuth(false)
        }
    )
  }, [])

  if (loading) {
    return <Spinner animation={"grow"}/>
  }

  return (
    <BrowserRouter>
      <NavBar/>
      <AppRouter />
    </BrowserRouter>
  );
}

export default App;
