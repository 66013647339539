import React, {useState} from 'react';
import {useHistory} from "react-router-dom";
import {BOOK_ROUTE} from "../utils/consts";
import Image from "react-bootstrap/Image";
import ModalDeleteBook from "./ModalDeleteBook";

const BookDeleteRow = ({book}) => {
  const history = useHistory()
  const [show, setShow] = useState(false);

  return (
    <>
      <tr onClick={()=>setShow(!show)}>
        <td width={'3em'}>{book.id}</td>
        <td><Image width={100} src={process.env.REACT_APP_API_URL + book.cover} onClick={() => history.push(BOOK_ROUTE + '/' + book.id)}/></td>
        <td>{book.title}</td>
        <td>{book.author}</td>
      </tr>
      <ModalDeleteBook book={book} show={show} onHide={()=>setShow(false)}/>
    </>
  );
};

export default BookDeleteRow;