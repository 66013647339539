import React from 'react';
import {useHistory} from "react-router-dom";
import {Card, Col} from "react-bootstrap";
import {BOOK_ROUTE} from "../utils/consts";
import Image from "react-bootstrap/Image";

const BookItem = ({book}) => {
  const history = useHistory()
  return (
    <Col sm={12} md={6} lg={3} className={"mt-3"} onClick={() => history.push(BOOK_ROUTE + '/' + book.id)}>
      <Card style={{width: 200, cursor: 'pointer'}} border={"dimgray"} className={' mx-auto'}>
        <Image width={200-2} height={257} src={process.env.REACT_APP_API_URL + book.cover}/>
        <div className={'m-1'}>
          <div className={'text-black-50'}>{book.author}</div>
          <div>{book.title}</div>
        </div>
      </Card>
    </Col>
  );
};

export default BookItem;